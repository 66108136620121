<template>
  <div>
    <b-modal
      :id="modalId"
      size="lg"
      body-class="px-0 px-md-3 py-0 py-md-3"
      footer-class="d-flex flex-column flex-md-row"
      centered
      :ok-title="$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.buttons.ok-title')"
      :cancel-title="$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.buttons.cancel-title')"
      cancel-variant="outline-secondary"
      :ok-disabled="isUnableToAccept"
      @ok="ok"
      @cancel="slide = 0"
    >
      <div class="d-flex flex-column">
        <b-carousel ref="myCarousel" fade v-model="slide" controls indicators no-wrap :interval="0">
          <b-carousel-slide v-for="item in items" :key="item.title">
            <template v-slot:img>
              <b-card :img-src="item.image ? item.image : null" img-top class="mx-1 mx-md-6 my-1 my-md-5">
                <b-card-text class="text-dark text-center">
                  <hs-icon v-if="item.icon" class="text-primary" :icon="item.icon" :size="55" />
                  <h3 class="mt-3 font-weight-bold">
                    {{ item.title }}
                  </h3>
                  <p class="mt-3" v-if="item.description">{{ item.description }}</p>
                </b-card-text>
              </b-card>
            </template>
          </b-carousel-slide>
        </b-carousel>
        <hs-checkbox class="mt-2 mx-2" v-if="isLastSlide" v-model="accepted">
          {{ $t('landing-page.views.sparkmembers-landing-page.components.alert-modal.accepted-checkbox-text') }}
        </hs-checkbox>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'AlertModal',
  data() {
    return {
      slide: 0,
      accepted: false,
      items: [
        {
          image: require('@/assets/images/SparkMembers/LandingPage/SparkMembersLandingPage/components/AlertModal/virtualpapers.svg'),
          title: this.$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.1.title'),
        },
        {
          icon: 'box-full',
          title: this.$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.2.title'),
          description: this.$t(
            'landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.2.description'
          ),
        },
        {
          icon: 'file-certificate',
          title: this.$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.3.title'),
          description: this.$t(
            'landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.3.description'
          ),
        },
        {
          icon: 'usd-circle',
          title: this.$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.4.title'),
          description: this.$t(
            'landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.4.description'
          ),
        },
        {
          icon: 'user-graduate',
          title: this.$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.5.title'),
          description: this.$t(
            'landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.5.description'
          ),
        },
        {
          icon: 'wallet',
          title: this.$t('landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.6.title'),
          description: this.$t(
            'landing-page.views.sparkmembers-landing-page.components.alert-modal.carousel.6.description'
          ),
        },
      ],
    };
  },
  computed: {
    isLastSlide() {
      return this.slide === this.items.length - 1;
    },
    isUnableToAccept() {
      return !this.isLastSlide || !this.accepted;
    },
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  watch: {
    slide(value) {
      const element = this.$refs.myCarousel.$el;

      element.querySelector('.carousel .carousel-control-prev').style.display = value === 0 ? 'none' : 'flex';
      element.querySelector('.carousel .carousel-indicators').style.top = value === 0 ? '240px' : '15px';
    },
  },
  methods: {
    ok() {
      if (this.isUnableToAccept) return;

      this.slide = 0;
      this.$emit('accepted');
    },
  },
};
</script>

<style lang="scss">
.carousel {
  border: 1px solid $grey-17;

  .carousel-indicators {
    top: 240px;

    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $cherry-light;
    }
  }

  .carousel-control-prev {
    opacity: 1;
    background: linear-gradient(270deg, rgba(204, 249, 251, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: none;

    .carousel-control-prev-icon {
      background-image: url('~@/assets/images/SparkMembers/LandingPage/SparkMembersLandingPage/components/AlertModal/right_arrow.svg');
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }

  .carousel-control-next {
    opacity: 1;
    background: linear-gradient(270deg, rgba(204, 249, 251, 0.5) 0%, rgba(255, 255, 255, 0) 100%);

    .carousel-control-next-icon {
      background-image: url('~@/assets/images/SparkMembers/LandingPage/SparkMembersLandingPage/components/AlertModal/right_arrow.svg');
    }
  }
}

@media only screen and (max-width: $screen-bs-md) {
  .carousel-indicators {
    display: none;
  }
}
</style>
